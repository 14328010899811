import toast from "react-hot-toast";
import  {client} from "@/app/ApolloClient";
import {gql} from "@apollo/client";

var query = gql`
 query getOrgs {
      myOrganizations {
        id
        name
      }
    }
`;


export function apiGetMyOrganizations() {
    return new Promise(async (resolve, reject) => {

        client
            .mutate({
                mutation: query,
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result.data.myOrganizations);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get organizations");
                reject(err); // R
            })
    });
}
