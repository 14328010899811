"use client";

import {ApolloLink, createHttpLink, HttpLink, useMutation} from "@apollo/client";
import {
    ApolloNextAppProvider,
    ApolloClient,
    InMemoryCache,
    SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import React, {useEffect, useState} from "react";
import {setContext} from "@apollo/client/link/context";
import {PmStore} from "@/state/store";


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = PmStore.getState().token;

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});


const httpLink = createHttpLink({
    uri: "https://rentalflow.dwai.xyz/api/graphql/",
});

export const client = new ApolloClient({
    link:  authLink.concat(httpLink), // Replace with your GraphQL endpoint
    cache: new InMemoryCache(),
});